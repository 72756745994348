import React, { Component } from "react";
import Sitenavbar from "./components/navbar/navbar";
import Banner from "./components/banner/banner";
import Sitecontent from "./components/siteContent/siteContent";
import Sitefooter from "./components/footer/footer";
import SrollToTop from "./components/scrollToTop/scrollToTop";
import { Container } from "react-bootstrap";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <header>
          <Sitenavbar navTitle="PD" />
        </header>
        <main>
          <Container fluid>
            <Banner />
            <Sitecontent />
          </Container>
        </main>
        <footer>
          <Container fluid>
            <Sitefooter />
            <SrollToTop />
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default App;
