import React, { Component } from "react";
import { connect } from "../../siteData";
import "./footer.scss";

class Sitefooter extends Component {
  connectItems = connect.map((item, index) => {
    return (
      <div className="d-flex contact-item" key={index}>
        <span className="contact-icon">
          <i className={item.locationIconClass}></i>
        </span>
        <a href={item.locationLink} target="_blank" rel="noopener noreferrer">
          {item.locationName}
        </a>
      </div>
    );
  });

  render() {
    return (
      <section id="connect">
        <div className="site-section gray-bg">
          <h3 className="section-title">Connect</h3>
          <div className="section-content">
            <p>
              For any queries or if you want to say <span className="strong">Hi</span>, you can reach me on these platforms. I will try to respond ASAP.
            </p>

            {this.connectItems}
          </div>
        </div>
      </section>
    );
  }
}

export default Sitefooter;
