import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./banner.scss";
import prasad_resume from "../../assets/Prasad P.D Resume - October - 2021.pdf";

class Banner extends Component {
  render() {
    return (
      <section id="home">
        <Row className="banner">
          <Col xs={12} md={12}>
            <div className="banner-text">
              <span>Prasad Dasanatti</span>
              <span>Front-end Developer</span>

              <a href={prasad_resume} target="_blank" rel="noopener noreferrer" className="btn download-btn">
                Download my resume
              </a>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default Banner;
