import React, { Component } from "react";
import { skills, tools, workexp, projectDetails } from "../../siteData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./siteContent.scss";
import prasad_photo from "../../assets/img/prasad_photo.jpeg";

class Sitecontent extends Component {
  skillsGroup = skills.map((item, index) => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{item.skill}</Tooltip>} key={index}>
        <div className="skill-item" tabIndex="0" aria-label={item.skill}>
          <i className={item.iconsClass}></i>
        </div>
      </OverlayTrigger>
    );
  });

  toolsGroup = tools.map((item, index) => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{item.tool}</Tooltip>} key={index}>
        <div className="skill-item" tabIndex="0" aria-label={item.tool}>
          <i className={item.iconsClass}></i>
        </div>
      </OverlayTrigger>
    );
  });

  workExpGroup = workexp.map((item, index) => {
    return (
      <div className="card" key={index}>
        <div className="card-body work-exp">
          <h5 className="card-title strong">{item.company}</h5>
          <p className="card-text">
            <span className="strong">{item.position}</span>
          </p>
          <p className="card-text">{item.duration}</p>
          <p className="card-text">{item.location}</p>
        </div>
      </div>
    );
  });

  projectWorks = projectDetails.map((item, index) => {
    return (
      <a href={item.projectLink} target="_blank" rel="noopener noreferrer" className="card" key={index}>
        <img src={item.projectImage} alt="triangle" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title strong">{item.projectTitle}</h5>
          <p>
            <span className="strong">{item.projectSubTitle}</span>
          </p>
          <p>{item.projectDesc}</p>

          <p>{item.projectSkills}</p>
        </div>
      </a>
    );
  });

  render() {
    return (
      <React.Fragment>
        <section id="about">
          <div className="site-section">
            <h3 className="section-title">About me</h3>
            <div className="row flex-wrap-reverse">
              <div className="col-md-8 col-sm12">
                <div className="section-content">
                  <p>I am an information science engineering graduate with 8 years of experience as a Front-End developer. To me, coding is an art and I love what I do. I enjoy the challenge of finding elegant solutions to complex problems.</p>

                  <p>Below are the skills and tools I use in my day-to-day tasks:</p>

                  <div className="d-flex flex-wrap skill-item-container">{this.skillsGroup}</div>
                  <div className="d-flex flex-wrap skill-item-container">{this.toolsGroup}</div>

                  <br />

                  <p>
                    I can speak <span className="strong">English, Hindi, and Kannada.</span>
                  </p>

                  <p>
                    I love to{" "}
                    <span className="strong">
                      read books, painting, travelling, photography, and of course watching movies and listening to some good music.{" "}
                      <span role="img" arial-hidden="true" aria-label="smile emoji">
                        😁
                      </span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm12 text-center">
                <img src={prasad_photo} alt="Prasad" className="prasad-photo" />
              </div>
            </div>
          </div>

          <div className="site-section gray-bg">
            <h3 className="section-title">Work experience</h3>
            <div className="section-content">
              <div className="card-deck">{this.workExpGroup}</div>
            </div>
          </div>
        </section>

        <section id="works">
          <div className="site-section">
            <h3 className="section-title">Few of my works</h3>

            <div className="section-content">
              <p>Below are some of my additional works done other than the regular office work:</p>

              <div className="card-deck">{this.projectWorks}</div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Sitecontent;
