import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { navLinks } from "../../siteData";

import "./navbar.scss";

class Sitenavbar extends Component {
  render() {
    const navLinksGroup = navLinks.map((linkItem, linkIndex) => {
      return (
        <Nav.Link href={linkItem.link} key={linkIndex}>
          {linkItem.title}
        </Nav.Link>
      );
    });

    return (
      <Navbar className="bg-white" expand="lg" fixed="top">
        <Navbar.Brand href="#home">{this.props.navTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">{navLinksGroup}</Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Sitenavbar;
