import React, { Component } from "react";
import "./scrollToTop.scss";

class SrollToTop extends Component {
  handleClick = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return <div className="scroll-to-top" onClick={this.handleClick} title="Scroll To Top" role="button" tabIndex="0" aria-label="Scroll To Top" aria-disabled="false"></div>;
  }
}

export default SrollToTop;
