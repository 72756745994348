import triangle from "./assets/img/triangle.png";
import weather from "./assets/img/weather.png";
import codepan from "./assets/img/codepen.jpg";
import colorBlend from "./assets/img/colorBlend.png";

export const navLinks = [
  {
    title: "Home",
    link: "#home",
  },
  {
    title: "About",
    link: "#about",
  },
  {
    title: "Works",
    link: "#works",
  },
  // {
  //   title: "Blog",
  //   link: "#blog"
  // },
  {
    title: "Connect",
    link: "#connect",
  },
];

export const skills = [
  {
    skill: "HTML 5",
    iconsClass: "fab fa-html5",
  },
  {
    skill: "CSS 3",
    iconsClass: "fab fa-css3-alt",
  },
  {
    skill: "SASS",
    iconsClass: "fab fa-sass",
  },
  {
    skill: "JavaScript",
    iconsClass: "fab fa-js",
  },
  {
    skill: "React JS",
    iconsClass: "fab fa-react",
  },
  {
    skill: "Bootstrap",
    iconsClass: "fab fa-bootstrap",
  },
  {
    skill: "Responsive Web Development",
    iconsClass: "fas fa-mobile-alt",
  },
];

export const tools = [
  {
    tool: "VS Code",
    iconsClass: "fas fa-laptop-code",
  },
  {
    tool: "Firebase",
    iconsClass: "fas fa-fire",
  },
  {
    tool: "Couch DB",
    iconsClass: "fas fa-couch",
  },
  {
    tool: "GitHub",
    iconsClass: "fab fa-github",
  },
  {
    tool: "JIRA",
    iconsClass: "fab fa-jira",
  },
  {
    tool: "Photoshop",
    iconsClass: "fab fa-adobe",
  },
];

export const workexp = [
  {
    company: "IBM India Pvt. Ltd.",
    position: "Senior System Engineer (UI)",
    duration: "Jan 2017 - Present",
    location: "Bengaluru, India",
  },
  {
    company: "Mobile Programming LLC.",
    position: "Software Developer",
    duration: "May 2016 - Jan 2017",
    location: "Bengaluru, India",
  },
  {
    company: "CSL.India",
    position: "Software Developer",
    duration: "Aug 2013 - Apr 2016",
    location: "Belagavi, India",
  },
];

export const projectDetails = [
  {
    projectLink: "https://prasaddasanatti.in/triangle/",
    projectImage: triangle,
    projectTitle: "TRIANGLE",
    projectSubTitle: "Low poly pattern generator",
    projectDesc: "This app generates low poly pattern images which can be exported into SVG or PNG format with desired dimensions. This app is powered by trianglify, written by Quinn Rohlf.",
    projectSkills: "The app has been created using React JS with Redux, SASS, and Bootstrap 4.",
  },
  {
    projectLink: "https://prasaddasanatti.in/weather/",
    projectImage: weather,
    projectTitle: "Weather App",
    projectSubTitle: "Demo weather app",
    projectDesc: "This demo app uses real-time weather data from OpenWeatherMap and displays it on the screen. At every 10 minutes, the data will be refreshed.",
    projectSkills: "Created this app using React JS with Redux, SASS, and OpenWeatherMap API.",
  },
  {
    projectLink: "https://prasaddasanatti.in/color-blend/",
    projectImage: colorBlend,
    projectTitle: "ColorBlend",
    projectSubTitle: "Color gradient generator",
    projectDesc: "This lite weight app generates CSS gradient styles(Linear, Radial, and Conical). 'N' number of colors can be added dynamically to the color list. ",
    projectSkills: "Used React JS with Redux, Bootstrap 4, and SASS to create this app.",
  },
  {
    projectLink: "https://codepen.io/prasad-d",
    projectImage: codepan,
    projectTitle: "Codepen",
    projectSubTitle: "Reusable code snippets",
    projectDesc: "Worked on several reusable code snippets on codepen platform.",
    projectSkills: "These pens have been created using HTML, SASS, and JavaScript.",
  },
];

export const connect = [
  {
    locationName: "prasad.dasanatti@gmail.com",
    locationLink: "mailto:prasad.dasanatti@gmail.com",
    locationIconClass: "far fa-envelope",
  },
  {
    locationName: "LinkedIn",
    locationLink: "https://www.linkedin.com/in/prasad-dasanatti",
    locationIconClass: "fab fa-linkedin",
  },
  {
    locationName: "Codepen",
    locationLink: "https://codepen.io/prasad-d",
    locationIconClass: "fab fa-codepen",
  },
  {
    locationName: "Facebook",
    locationLink: "https://www.facebook.com/prasad.dasanatti",
    locationIconClass: "fab fa-facebook",
  },
];
